import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const Contact = () => (
  <Layout>
    <Seo title="Kontakt" />
    <div className="font-courier w-4/5 md:w-3/5 mx-auto my-4 rounded shadow p-4">
      <h1 className="text-xl font-bold underline py-4 px-2">Kontakt:</h1>
      <div className="divide-y divide-gray-500">
        <p className="py-4 text-center">
          info@bildhauer-vale.de <br />
          Hochgernstraße 16 <br />
          83224 Grassau
        </p>
        <p className="py-4 text-justify">
          Da ich an Social Media kein Interesse habe, ist – zumindest bewusst –
          nichts von mir / über mich auf Facebook, Twitter, Instagram etc. zu
          finden. Wenn Sie Fragen haben oder Kaufinteresse besteht, kontaktieren
          Sie mich am Besten per Email.
        </p>
      </div>
    </div>
  </Layout>
)
export default Contact
